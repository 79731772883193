import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='about'
export default class extends Controller {
  connect() {
    $(".accordian-tabs .set > a").on("click", function() {
      if($(this).hasClass("active-tab")) {
        $(this).removeClass("active-tab");
        $(this).siblings(".content").slideUp(200);
      }
      else {
        $(".accordian-tabs .set > a").removeClass("active-tab");
        $(this).addClass("active-tab");
        $(".content").slideUp(200);
        $(this).siblings(".content").slideDown(200);
      }
    });

    $('.new_inquiry').validate({
      rules: {
        "inquiry[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "inquiry[phone_number]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10,
        }
      },
      messages: {
        "inquiry[email]": {
          required: 'The email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Contains @ and .'
        },
        "inquiry[phone_number]": {
          phoneregex: 'The phone contain only 10 digits.',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
        }
      }
    });

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');

    $.validator.addMethod('phoneregex', function(value, element){
      console.log('hey');
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');
  }
}
