import { Controller } from "@hotwired/stimulus"

// Connects to 'data-controller': "order"
export default class extends Controller {
  connect() {
    console.log('order call')
    $('.select_now_plan').click(function(){
      console.log('select clicked')
      $(".order-now-section").show();
      console.log('show')
    });
    $('.select-plan').click(function(){
      $('.order-now-section').show();
      var subscription_id = $('.radio-plan-ip:checked').val()
      var acceptCheckbox = $("#accept-checkbox:checked")
      if(subscription_id == undefined){
        $('.term-error').text('Please select a plan.');
        $('.term-error').show();
        return false
      }
      if(subscription_id.length > 0 && acceptCheckbox.length > 0 ){
        $('.loading-screen').show();
      }else{
        message = 'Please accept terms and policy.'
        if(subscription_id.length < 1){
          message = 'Please select a plan.'
        }
        $('.term-error').text(message);
        $('.term-error').show();
        return false;
      }
      $('.radio-plan-ip:checked').next().find('.add-on-link').click()
    });
  }

  search5gAvailability(event){
    var element = event.currentTarget;
    var zipCode = document.getElementById('coverage-code').value;
    pulling(zipCode, 0)
    console.log('search5gAvailability call');
  }
}

window.pulling = function(zipCode, repeatCount){
  setTimeout(function(){
    $.ajax({
      type: 'post',
      url: "/coverage/" + zipCode + '/pulling',
      data: { 'repeat_count': 1 },
      dataType: 'script',
      success: function(){
        console.log('ajax success');
        waitAndLoad();
      },
      error : function(xhr, textStatus, errorThrown) {
        console.log('ajax failure');
        console.log(errorThrown)
      },
    });
  }, 3000);
}

window.waitAndLoad = function(){
  console.log('waitAndLoad call from Controller')
  $('.finder-loader').hide()
  $('.finder-loader + p').hide()
  $('#result-box').show(300);
  $('.findout-loader').show()
  $('.findout-loader + p').show()
  if($('#zipcode_finder_message').data('id') == 'coverage'){
    // $('.order_now_btn').show();
    console.log('id: ' + id)
    setTimeout( function(){
      $('.order_now_btn').show(300)
    }, 5000);
  }else{
    setTimeout( function(){
      window.location.reload();
    }, 3000);
  }
}
