import { Controller } from "@hotwired/stimulus"

// Connects to data-controller='stripe'
export default class extends Controller {
  connect() {
    let appName = $('#stripe-data').data('appname')
    let email = $('#stripe-data').data('email')
    let key = $('#stripe-data').data('key')

    if(appName == undefined){
      appName = 'SuperCloudEnergy'
    }
    $('.form-ip-sec').remove();
    $(window).scrollTop(0);

    var handler = StripeCheckout.configure({
      key: key,
      //get a publishable key that we put in editor depending on environment: production or development
      locale: 'auto',
      //handle translation
      name: `${appName} Subscription`,
      email: email,
      description: 'Add your credit card information',
      panelLabel: "Add Payment Method",
      allowRememberMe: false,
      token: function (token) {
        var form = document.getElementById('billing-create-payment-method');
        var hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'hidden');
        hiddenInput.setAttribute('name', 'stripeToken');
        hiddenInput.setAttribute('value', token.id);
        spinRun();
        form.appendChild(hiddenInput);
        form.submit();
      }
    });

    handler.open();
    window.addEventListener('popstate', function() {
      handler.close();
    });
  }
}

window.spinRun = function(){
  var loadingSpin = '<p><i class="fa fa-spinner fa-spin" style="font-size: 3rem;"></i></p><p>Please do not refresh the page and wait while we are processing your payment. This can take a few minutes.</p>'
  $('.overlay-screen').addClass('show_overlay');
  $('.overlay-screen').html(loadingSpin);
}
